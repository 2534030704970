import React, { useState, useEffect, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useOnClickOutside } from "../../hooks";
import Link from "gatsby-plugin-transition-link";

import Burger from "./Burger";
import OffCanvas from "./OffCanvas";
import ButtonSolid from "../Button/ButtonSolid";
// import ButtonGhost from "../Button/ButtonGhost";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

const MainNav = ({ headerStyle, scrolled, offcanvasOffset }) => {
  // determine if offcanvas is open
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);

  // determine button styles
  const [buttonAltStyle, setButtonAltStyle] = useState(0);

  const [subMenuHovering1, setSubMenuHovering1] = useState(false);
  const isHoveringSubMenu1 = () => setSubMenuHovering1(true);
  const notHoveringSubMenu1 = () => setSubMenuHovering1(false);

  const [subMenuHovering2, setSubMenuHovering2] = useState(false);
  const isHoveringSubMenu2 = () => setSubMenuHovering2(true);
  const notHoveringSubMenu2 = () => setSubMenuHovering2(false);

  const [subMenuHovering3, setSubMenuHovering3] = useState(false);
  const isHoveringSubMenu3 = () => setSubMenuHovering3(true);
  const notHoveringSubMenu3 = () => setSubMenuHovering3(false);

  // const [subMenuHovering4, setSubMenuHovering4] = useState(false);
  // const isHoveringSubMenu4 = () => setSubMenuHovering4(true);
  // const notHoveringSubMenu4 = () => setSubMenuHovering4(false);

  // handle click of navigation items
  const clickHandler = () => {
    setOffcanvasOpen(!offcanvasOpen);
  };

  // close offcanvas onclick outside
  const node = useRef();
  useOnClickOutside(node, () => setOffcanvasOpen(false));

  useEffect(() => {
    headerStyle !== "overlap" && scrolled === false
      ? setButtonAltStyle(0)
      : headerStyle === "overlap" && scrolled === false
      ? setButtonAltStyle(2)
      : setButtonAltStyle(0);
  }, [buttonAltStyle, scrolled, headerStyle]);

  const data = useStaticQuery(graphql`
    {
      darkLogo: file(relativePath: { eq: "global/Logo-Dark.png" }) {
        publicURL
      }
      teamMembers: allSanityTeamMember(sort: { fields: [order], order: ASC }) {
        edges {
          node {
            name
            slug {
              current
            }
          }
        }
      }
      portfolio: allSanityPortfolio(sort: { fields: [order], order: ASC }) {
        edges {
          node {
            title
            slug {
              current
            }
          }
        }
      }
    }
  `);

  // Define logos based on header style
  let logo = null;

  if (headerStyle === "overlap") {
    logo = data.darkLogo.publicURL;
  } else {
    logo = data.darkLogo.publicURL;
  }

  if (scrolled) {
    logo = data.darkLogo.publicURL;
  }

  if (offcanvasOpen) {
    logo = data.darkLogo.publicURL;
  }

  const navigation = {
    investmentCriteria: [
      {
        name: "Assurance Mezzanine",
        href: "/mezzanine-fund/",
      },
      {
        name: "Assurance Growth Partners",
        href: "/growth-partners/",
      },
    ],
  };

  return (
    <nav
      id="main-navigation"
      className={`h-18 w-full py-4 transition duration-300 ease-linear md:h-20 ${
        headerStyle === "overlap" ? "absolute lg:bg-transparent" : "lg:bg-white"
      } ${offcanvasOpen && "!bg-white"} ${
        scrolled && "!fixed top-0 left-0 z-50 w-full !bg-white !shadow-4xl"
      } `}
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container flex items-center justify-between">
        <div className="flex flex-auto items-center justify-start">
          <Link to="/">
            <img
              src={logo}
              alt="Assurance Logo"
              width={164}
              className="w-[133px] lg:w-[164px]"
            />
          </Link>
        </div>

        <div className="flex flex-auto items-center justify-end">
          <ul
            id="navigation-desktop"
            className="hidden lg:ml-6 lg:mr-10 lg:flex lg:flex-row lg:items-center lg:justify-end lg:space-x-5 xl:space-x-8"
          >
            <li>
              <Link
                to="/portfolio/"
                className={`inline font-body font-semibold no-underline ${
                  headerStyle === "overlap"
                    ? "text-white hover:text-white"
                    : "text-gray-900 hover:text-secondary-900"
                } ${scrolled && "text-gray-900 hover:text-secondary-900"}`}
              >
                Portfolio
              </Link>
            </li>
            <li
              className="relative"
              role="presentation"
              onMouseEnter={isHoveringSubMenu2}
              onMouseLeave={notHoveringSubMenu2}
            >
              <Link
                to="/team/"
                className={`relative inline pb-8 font-body font-semibold no-underline ${
                  headerStyle === "overlap"
                    ? "text-white hover:text-white"
                    : "text-gray-900 hover:text-secondary-900"
                } ${scrolled && "text-gray-900 hover:text-secondary-900"}`}
              >
                Team
              </Link>
              <ul
                className={`absolute top-0 z-10 -translate-x-8 transform flex-col space-y-5 bg-primary-900 px-6 py-6 shadow-4xl transition-all duration-300 ease-linear ${
                  subMenuHovering2
                    ? " visible translate-y-[52px] opacity-100"
                    : "invisible translate-y-20 opacity-0"
                }`}
              >
                {data.teamMembers.edges.map(({ node }, i) => {
                  return (
                    node.slug && (
                      <li key={i} className="whitespace-nowrap">
                        <Link
                          to={`/${node.slug.current}/`}
                          className="font-semibold text-white/80 no-underline hover:text-white"
                        >
                          {node.name}
                        </Link>
                      </li>
                    )
                  );
                })}
              </ul>
            </li>

            <li
              className="relative"
              role="presentation"
              onMouseEnter={isHoveringSubMenu3}
              onMouseLeave={notHoveringSubMenu3}
            >
              <Link
                to="#"
                className={`relative inline pb-8 font-body font-semibold no-underline ${
                  headerStyle === "overlap"
                    ? "text-white hover:text-white"
                    : "text-gray-900 hover:text-secondary-900"
                } ${scrolled && "text-gray-900 hover:text-secondary-900"}`}
              >
                Investment Criteria
              </Link>
              <ul
                className={`absolute top-0 z-10 -translate-x-8 transform flex-col space-y-5 bg-primary-900 px-6 py-6 shadow-4xl transition-all duration-300 ease-linear ${
                  subMenuHovering3
                    ? " visible translate-y-[52px] opacity-100"
                    : "invisible translate-y-20 opacity-0"
                }`}
              >
                {navigation.investmentCriteria.map((item) => (
                  <li key={item.name} className="whitespace-nowrap">
                    <Link
                      to={item.href}
                      className="font-semibold text-white/80 no-underline hover:text-white"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            <li>
              <Link
                to="/news/"
                className={`inline font-body font-semibold no-underline ${
                  headerStyle === "overlap"
                    ? "text-white hover:text-white"
                    : "text-gray-900 hover:text-secondary-900"
                } ${scrolled && "text-gray-900 hover:text-secondary-900"}`}
              >
                News
              </Link>
            </li>
          </ul>

          <div className="hidden items-center space-x-4 lg:inline-flex">
            <ButtonSolid
              modal="modal-contact"
              text="Contact"
              className="min-w-[160px]"
            />
          </div>

          <div className="lg:hidden" ref={node}>
            {/* Burger */}
            <Burger
              offcanvasOpen={offcanvasOpen}
              setOffcanvasOpen={setOffcanvasOpen}
              headerStyle={headerStyle}
              scrolled={scrolled}
              aria-controls="offcanvas-navigation"
            />

            {/* Mobile Nav */}
            <OffCanvas
              offcanvasOpen={offcanvasOpen}
              offcanvasOffset={offcanvasOffset}
              id="offcanvas-navigation"
            >
              <div className="relative h-full">
                <ul id="navigation-mobile" className="mb-16 px-4 text-center">
                  <Accordion
                    allowZeroExpanded={true}
                    className="flex flex-col space-y-8"
                  >
                    <li>
                      <Link
                        to="/portfolio/"
                        onClick={clickHandler}
                        className="text-3xl font-bold text-gray-900 no-underline transition-colors duration-300 ease-linear hover:text-primary-500"
                      >
                        Portfolio
                      </Link>
                    </li>

                    <li>
                      <AccordionItem uuid={1}>
                        <AccordionItemButton className="flex items-center justify-center focus:outline-none">
                          <AccordionItemState>
                            {(state) => {
                              return (
                                <>
                                  <div
                                    className={`mb-0 text-3xl font-bold no-underline transition-colors duration-300 ease-linear ${
                                      state.expanded
                                        ? "text-primary-500"
                                        : "text-gray-900 hover:text-primary-500"
                                    }`}
                                  >
                                    Team
                                  </div>
                                </>
                              );
                            }}
                          </AccordionItemState>
                        </AccordionItemButton>

                        <AccordionItemPanel className="pt-4">
                          <ul className="space-y-3.5">
                            <li className="whitespace-nowrap">
                              <Link
                                to={`/team/`}
                                onClick={clickHandler}
                                className="block bg-transparent font-normal text-gray-900 no-underline hover:text-primary-500"
                              >
                                All Team Members
                              </Link>
                            </li>
                            {data.teamMembers.edges.map(({ node }, i) => {
                              return (
                                node.slug && (
                                  <li key={i} className="whitespace-nowrap">
                                    <Link
                                      to={`/${node.slug.current}/`}
                                      onClick={clickHandler}
                                      className="block bg-transparent font-normal text-gray-900 no-underline hover:text-primary-500"
                                    >
                                      {node.name}
                                    </Link>
                                  </li>
                                )
                              );
                            })}
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </li>

                    <li>
                      <AccordionItem uuid={2}>
                        <AccordionItemButton className="flex items-center justify-center focus:outline-none">
                          <AccordionItemState>
                            {(state) => {
                              return (
                                <>
                                  <div
                                    className={`mb-0 text-3xl font-bold no-underline transition-colors duration-300 ease-linear ${
                                      state.expanded
                                        ? "text-primary-500"
                                        : "text-gray-900 hover:text-primary-500"
                                    }`}
                                  >
                                    Investment Criteria
                                  </div>
                                </>
                              );
                            }}
                          </AccordionItemState>
                        </AccordionItemButton>

                        <AccordionItemPanel className="pt-4">
                          <ul className="space-y-3.5">
                            {navigation.investmentCriteria.map((item) => (
                              <li key={item.name} className="whitespace-nowrap">
                                <Link
                                  to={item.href}
                                  onClick={clickHandler}
                                  className="block bg-transparent font-normal text-primary-900 no-underline hover:text-primary-500"
                                >
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </li>

                    <li>
                      <Link
                        to="/news/"
                        onClick={clickHandler}
                        className="text-3xl font-bold text-gray-900 no-underline transition-colors duration-300 ease-linear hover:text-primary-500"
                      >
                        News
                      </Link>
                    </li>
                  </Accordion>
                </ul>

                <div className="absolute inset-x-0 bottom-0 mx-auto flex w-full max-w-[200px] flex-wrap items-center justify-center">
                  <ButtonSolid
                    modal="modal-contact"
                    text="Contact"
                    className="w-full md:w-auto"
                  />
                </div>
              </div>
            </OffCanvas>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default MainNav;
