import React from "react";

import phone from "../../images/global/phone.svg";
import signIn from "../../images/global/sign-in.svg";

const UtilityNav = ({ headerStyle }) => {
  return (
    <div
      id="utlity-navigation"
      className={`hidden bg-primary-900 py-1.5 lg:block ${
        headerStyle === "overlap" ? "" : ""
      }`}
    >
      <div className="container">
        <div className="flex items-center justify-between">
          <div>
            <a
              href="tel:407-801-1436"
              className="flex items-center text-sm text-white no-underline hover:text-white"
            >
              <img src={phone} className="mr-2" />
              (407) 801-1436
            </a>
          </div>
          <div>
            <a
              href="https://viewmyportal.investorflow.com/"
              target="_blank"
              rel="noreferrer"
              className="flex items-center text-sm font-semibold text-white no-underline hover:text-white"
            >
              <img src={signIn} className="mr-2" />
              Investor Portal
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UtilityNav;
