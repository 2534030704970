import React, { useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import Header from "./Navigation/Header";
import Footer from "./Footer/Footer";

const Layout = ({ children, headerStyle, hideHeader, hideFooter }) => {
  const [bodyOffset, setBodyOffset] = useState(0);
  return (
    <div>
      <Helmet>
        <script
          async
          src="https://tag.clearbitscripts.com/v1/pk_81e559a9a43cae966fda50707e204a4e/tags.js"
          referrerpolicy="strict-origin-when-cross-origin"
        ></script>
      </Helmet>
      <Header
        headerStyle={headerStyle}
        hideHeader={hideHeader}
        setBodyOffset={setBodyOffset}
      />
      <main id="body-content" style={{ paddingTop: bodyOffset + "px" }}>
        {children}
      </main>
      <Footer hideFooter={hideFooter} />
    </div>
  );
};

Layout.propTypes = {
  headerStyle: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Layout;
