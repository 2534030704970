import React from "react";
import Link from "gatsby-plugin-transition-link";
import { StaticImage } from "gatsby-plugin-image";

import ModalContact from "../Modal/ModalContact";
import nestLogo from "../../images/global/NEST.svg";

const Footer = ({ hideFooter }) => {
  const getYear = () => {
    return new Date().getFullYear();
  };

  const navigation = {
    about: [
      {
        name: "Portfolio",
        href: "/portfolio/",
      },
      {
        name: "Team",
        href: "/team/",
      },
      {
        name: "Assurance Mezzanine",
        href: "/mezzanine-fund/",
      },
      {
        name: "Assurance Growth Partners",
        href: "/growth-partners/",
      },
      {
        name: "News",
        href: "/news/",
      },
    ],
  };

  return (
    <>
      <footer
        className={`relative z-20 bg-primary-900 pt-10 pb-12 text-center md:pt-20 md:pb-6 md:text-left ${
          hideFooter && "hidden"
        }`}
      >
        <div className="container">
          <div className="mb-10 grid space-y-10 md:mb-20 md:grid-cols-12 md:justify-between md:space-y-0 md:space-x-16 xl:space-x-24">
            <div className="md:col-span-4 md:col-start-1">
              <Link to="/">
                <StaticImage
                  src="../../images/global/Logo-Light.png"
                  alt="Assurance Logo"
                  placeholder="none"
                  width={118}
                  className="mb-10 w-[118px] md:mb-0 md:w-[107px]"
                />
              </Link>

              <div className="flex items-center justify-center space-x-6 md:hidden md:justify-start">
                <a
                  href="https://www.linkedin.com/company/assurance-mezzanine-fund/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white no-underline hover:text-white"
                >
                  <i className="fab fa-linkedin-in text-xl"></i>
                </a>
              </div>
            </div>

            <div className="md:col-span-8 md:col-end-13">
              <div className="grid justify-center gap-y-10 md:flex md:grid-cols-2 md:justify-end md:gap-y-0 md:space-x-12 lg:space-x-20">
                <div>
                  <div className="mb-3 font-heading text-lg font-bold uppercase tracking-wider text-white md:mb-5">
                    Contact
                  </div>

                  <ul className="flex flex-col space-y-2">
                    <li>
                      <a
                        href="tel:407-801-1436"
                        className="text-sm font-normal text-white/70 no-underline hover:text-white"
                      >
                        (407) 801-1436
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:dellis@assurancecapitalfunds.com"
                        className="text-sm font-normal text-white/70 no-underline hover:text-white"
                      >
                        dellis@assurancecapitalfunds.com
                      </a>
                    </li>
                  </ul>
                </div>

                <div>
                  <div className="mb-3 font-heading text-lg font-bold uppercase tracking-wider text-white md:mb-5">
                    Address
                  </div>

                  <ul className="flex flex-col space-y-2">
                    <li>
                      <address className="not-italic">
                        <a
                          href="https://maps.app.goo.gl/5S8wPoWW6FYDj2XS6"
                          target="_blank"
                          rel="noreferrer"
                          className="text-sm font-normal leading-[30px] text-white/70 no-underline hover:text-white"
                        >
                          1000 Legion Place Suite 840
                          <br />
                          Orlando, Florida 32801
                        </a>
                      </address>
                    </li>
                  </ul>
                </div>

                <div>
                  <div className="mb-3 font-heading text-lg font-bold uppercase tracking-wider text-white md:mb-5">
                    About
                  </div>

                  <ul className="flex flex-col space-y-2">
                    {navigation.about.map((item) => (
                      <li key={item.name} className="whitespace-nowrap">
                        <Link
                          to={item.href}
                          className="text-sm font-normal text-white/70 no-underline hover:text-white"
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col flex-wrap justify-items-center border-t border-white/30 pt-10  md:flex-row md:flex-nowrap md:items-center md:justify-between md:pt-6">
            <div className="md:flex md:items-center">
              <ul className="flex flex-wrap items-center justify-center space-y-3 md:justify-start md:space-y-0 md:space-x-4">
                {/* <div className="flex w-full flex-wrap justify-center space-x-4 md:w-auto"> */}
                <li className="w-full font-body text-sm text-white/70 md:w-auto">
                  © {getYear()} Assurance Capital LLC
                </li>

                <li className="w-full text-sm md:w-auto">
                  <Link
                    to="/privacy-policy/"
                    className="font-body font-normal text-white no-underline hover:text-white md:text-white/70"
                  >
                    Privacy Policy
                  </Link>
                </li>

                <li className="w-full text-sm md:w-auto">
                  <a
                    className="group flex items-center justify-center space-x-1 text-sm font-normal text-white/70 no-underline hover:text-white md:justify-start"
                    href="https://www.wisedigitalpartners.com/nest-by-wise/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Powered by</span>
                    <img
                      src={nestLogo}
                      alt="NEST logo"
                      className="opacity-70 transition-opacity duration-300 ease-linear group-hover:opacity-100"
                    />
                  </a>
                </li>
                {/* </div> */}
              </ul>
            </div>

            <div className="w-full text-sm md:w-auto">
              <div className="hidden items-center justify-center space-x-6 md:flex md:justify-start">
                <a
                  href="https://www.linkedin.com/company/assurance-mezzanine-fund/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white no-underline hover:text-white"
                >
                  <i className="fab fa-linkedin-in text-xl"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <ModalContact />
    </>
  );
};

export default Footer;
