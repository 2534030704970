import React from "react";
import MicroModal from "micromodal";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import Contact from "../Form/Contact";
// import BookMeeting from "../Form/BookMeeting";

const StyledModal = styled.div`
  .modal {
    &[aria-hidden="false"] {
      .overlay {
        animation: mmfadeIn 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="false"] {
      .content-wrapper {
        animation: mmslideIn 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="true"] {
      .overlay {
        animation: mmfadeOut 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="true"] {
      .content-wrapper {
        animation: mmslideOut 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }
`;

const StyledTabs = styled.div`
  .react-tabs__tab-list {
    ${tw`grid grid-cols-2 mb-10 md:mb-14 border-b! border-gray-300 overflow-hidden`}
  }
  .react-tabs__tab {
    ${tw`relative text-sm text-secondary-900/50 font-semibold pt-0 pb-4 bg-white rounded-none flex items-center justify-center w-full border-none bottom-0 transition-colors duration-300 ease-linear`}
    &:after {
      content: "";
      ${tw`w-0 h-1 bg-secondary-900! absolute left-0! right-auto! bottom-0! transition-all duration-500 ease-linear`}
    }
  }
  .react-tabs__tab--selected {
    ${tw`text-secondary-900`}
    &:after {
      ${tw`w-full`}
    }
  }
`;

const ContactModal = () => {
  if (typeof window !== "undefined") {
    MicroModal.init({
      openTrigger: "data-modal-open",
      closeTrigger: "data-modal-close",
      disableFocus: true,
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
    });
  }

  return (
    <StyledModal>
      <div
        className="modal relative z-50 hidden"
        id="modal-contact"
        aria-hidden="false"
      >
        <div
          className="overlay fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center bg-black/40 outline-none"
          tabIndex="-1"
          data-modal-close
        >
          <div
            className="content-wrapper ml-auto h-screen w-full max-w-[720px] overflow-auto bg-white"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-center justify-between border-b border-gray-500/20 px-5 pt-14 pb-4 md:py-4">
              <a
                href="tel:407-801-1436"
                className="hover:text-primary-800 flex items-center font-bold text-secondary-900 no-underline hover:text-secondary-500"
              >
                <i className="fas fa-phone mr-2"></i>
                (407) 801-1436
              </a>
              <i
                className="close far fa-times cursor-pointer text-xl text-black transition-all duration-300 ease-linear hover:text-primary-500"
                data-modal-close
              ></i>
            </div>

            <div className="px-4 pt-8 pb-24 md:px-18">
              <header className="mb-8 text-center md:mb-10">
                <p className="heading-three text-[#26272B]">Contact Us</p>
              </header>

              <Contact />

              {/* <StyledTabs>
                <Tabs>
                  <TabList>
                    <Tab>Send a Message</Tab>
                    <Tab>Book a Meeting</Tab>
                  </TabList>

                  <TabPanel>
                    <p>
                      We welcome direct inquiries from entrepreneurs as well as
                      intermediaries. Our goal is to respond to your request
                      within two business days.
                    </p>
                    <Contact />
                  </TabPanel>

                  <TabPanel><BookMeeting /></TabPanel>
                </Tabs>
              </StyledTabs> */}
            </div>
          </div>
        </div>
      </div>
    </StyledModal>
  );
};
export default ContactModal;
